import React, { useState, useEffect } from "react";
import axios from "axios";
import url from "../../../globalUrl";

const SupervisedStudents = () => {
  const [studentDetails, setStudentDetails] = useState([]);

  const fetchSupervisedStudents = async () => {
    try {
      const response = await axios.get(`${url}/graduates`);
      console.log(response);
      setStudentDetails(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSupervisedStudents();
  }, []);

  return (
    <div>
      <h2>Supervised Students</h2>
      <table
        style={{
          border: "1px solid black",
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "18px" }}>
              Student Name
            </th>
            <th style={{ border: "1px solid black" }}>Student Email</th>
            <th style={{ border: "1px solid black" }}>Task Assigned</th>
            <th style={{ border: "1px solid black" }}>Submissions</th>
          </tr>
        </thead>
        <tbody>
          {studentDetails.length > 0 ? (
            studentDetails.map((student) => (
              <tr key={student.id}>
                <td style={{ border: "1px solid black", padding: "12px" }}>
                  {student.name}
                </td>
                <td style={{ border: "1px solid black" }}>{student.email}</td>
                <td style={{ border: "1px solid black" }}>
                  {student.assignedTasks}
                </td>
                <td style={{ border: "1px solid black" }}>
                  {student.submittedTasks}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" style={{ textAlign: "center" }}>
                Loading...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SupervisedStudents;
